<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar class="secondary ma-4">
                        <v-toolbar-title class="white--text text-h4">
                            Request Refund Form
                        </v-toolbar-title>
                    </v-toolbar>
                    <div class="px-2">
                        <v-container >
                            
                            <div class="d-flex flex-column justify-start" v-if="selectedPaymentMethod!=null">
                                <p class="d-flex justify-start font-weight-bold">
                                    Remark :
                                </p>
                                <v-textarea dense outlined 
                                :rules="rulesMaxLength"
                                placeholder="Start typing your remark..."
                                :counter="500"
                                v-model="remark" hint="Max 500 characters">

                                </v-textarea>
                            </div>
                            <div class="d-flex justify-end">
                                <v-btn color="primary" plain>
                                    Cancel
                                </v-btn>
                                <v-btn color="primary">
                                    Confirm
                                </v-btn>
                            </div>
                        </v-container>
                    </div>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
export default {
    components:{
    AConfirmation
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        rulesMaxLength:[v=>v.length<=500||'Max 500 Characters'],
        optionPaymentMethod:['Credit Card',"Bank Transfer"],
        optionBankType:["Public Bank","Bank Islam","Maybank","CIMB Bank","RHB Bank","Others"],
        selectedBankType:null,
        othersSelectedBankType:null,
        selectedPaymentMethod:null,
        remark:null,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    //
    },
    methods: {
        fetch(){

        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
        },
        cancelSubmit(){
            this.isPending = true;
        },
        create(){

        },
    }
}
</script>